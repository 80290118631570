export const initialSocketItem = {
  symbol: '--',
  base: '--',
  quote: '--',
  close: '--',
  otherInfo: {
    pricePrecision: '--',
  },
  rose1h: '--',
  rose4h: '--',
  rose24h: '--',
  high: '--',
  low: '--',
  amount: '--',
};
