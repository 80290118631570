import { default as indexPHXV4FouQIMeta } from "/home/wwwroot/butter_html/apps/h5/src/pages/gameDetail/index.vue?macro=true";
import { default as indexUdz3PTn7uaMeta } from "/home/wwwroot/butter_html/apps/h5/src/pages/gameDetailPC/index.vue?macro=true";
import { default as indexG1uJjkYeVVMeta } from "/home/wwwroot/butter_html/apps/h5/src/pages/index/index.vue?macro=true";
import { default as indexpNrYMj1xE3Meta } from "/home/wwwroot/butter_html/apps/h5/src/pages/login/index.vue?macro=true";
import { default as indexg0IwFtkpDoMeta } from "/home/wwwroot/butter_html/apps/h5/src/pages/loginPC/index.vue?macro=true";
import { default as index1fJstjI9mhMeta } from "/home/wwwroot/butter_html/apps/h5/src/pages/myGame/index.vue?macro=true";
import { default as indexHWTdV6KKsFMeta } from "/home/wwwroot/butter_html/apps/h5/src/pages/register/index.vue?macro=true";
import { default as indexKVtlRsqTe0Meta } from "/home/wwwroot/butter_html/apps/h5/src/pages/register/registerCode/index.vue?macro=true";
import { default as indexZ6ZuKsKAuRMeta } from "/home/wwwroot/butter_html/apps/h5/src/pages/register/registerSuccess/index.vue?macro=true";
import { default as indexCIsB6rhX5gMeta } from "/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/index.vue?macro=true";
import { default as indexVZzDUik1ENMeta } from "/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/registerCode/index.vue?macro=true";
import { default as indexwJ3oGAj4vEMeta } from "/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/registerSuccess/index.vue?macro=true";
export default [
  {
    name: indexPHXV4FouQIMeta?.name ?? "gameDetail___ar-sa",
    path: indexPHXV4FouQIMeta?.path ?? "/ar-sa/gameDetail",
    meta: indexPHXV4FouQIMeta || {},
    alias: indexPHXV4FouQIMeta?.alias || [],
    redirect: indexPHXV4FouQIMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/gameDetail/index.vue").then(m => m.default || m)
  },
  {
    name: indexPHXV4FouQIMeta?.name ?? "gameDetail___de-de",
    path: indexPHXV4FouQIMeta?.path ?? "/de-de/gameDetail",
    meta: indexPHXV4FouQIMeta || {},
    alias: indexPHXV4FouQIMeta?.alias || [],
    redirect: indexPHXV4FouQIMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/gameDetail/index.vue").then(m => m.default || m)
  },
  {
    name: indexPHXV4FouQIMeta?.name ?? "gameDetail___en-us",
    path: indexPHXV4FouQIMeta?.path ?? "/gameDetail",
    meta: indexPHXV4FouQIMeta || {},
    alias: indexPHXV4FouQIMeta?.alias || [],
    redirect: indexPHXV4FouQIMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/gameDetail/index.vue").then(m => m.default || m)
  },
  {
    name: indexPHXV4FouQIMeta?.name ?? "gameDetail___es-es",
    path: indexPHXV4FouQIMeta?.path ?? "/es-es/gameDetail",
    meta: indexPHXV4FouQIMeta || {},
    alias: indexPHXV4FouQIMeta?.alias || [],
    redirect: indexPHXV4FouQIMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/gameDetail/index.vue").then(m => m.default || m)
  },
  {
    name: indexPHXV4FouQIMeta?.name ?? "gameDetail___fr-fr",
    path: indexPHXV4FouQIMeta?.path ?? "/fr-fr/gameDetail",
    meta: indexPHXV4FouQIMeta || {},
    alias: indexPHXV4FouQIMeta?.alias || [],
    redirect: indexPHXV4FouQIMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/gameDetail/index.vue").then(m => m.default || m)
  },
  {
    name: indexPHXV4FouQIMeta?.name ?? "gameDetail___id-id",
    path: indexPHXV4FouQIMeta?.path ?? "/id-id/gameDetail",
    meta: indexPHXV4FouQIMeta || {},
    alias: indexPHXV4FouQIMeta?.alias || [],
    redirect: indexPHXV4FouQIMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/gameDetail/index.vue").then(m => m.default || m)
  },
  {
    name: indexPHXV4FouQIMeta?.name ?? "gameDetail___it-it",
    path: indexPHXV4FouQIMeta?.path ?? "/it-it/gameDetail",
    meta: indexPHXV4FouQIMeta || {},
    alias: indexPHXV4FouQIMeta?.alias || [],
    redirect: indexPHXV4FouQIMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/gameDetail/index.vue").then(m => m.default || m)
  },
  {
    name: indexPHXV4FouQIMeta?.name ?? "gameDetail___ja-jp",
    path: indexPHXV4FouQIMeta?.path ?? "/ja-jp/gameDetail",
    meta: indexPHXV4FouQIMeta || {},
    alias: indexPHXV4FouQIMeta?.alias || [],
    redirect: indexPHXV4FouQIMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/gameDetail/index.vue").then(m => m.default || m)
  },
  {
    name: indexPHXV4FouQIMeta?.name ?? "gameDetail___ko-kr",
    path: indexPHXV4FouQIMeta?.path ?? "/ko-kr/gameDetail",
    meta: indexPHXV4FouQIMeta || {},
    alias: indexPHXV4FouQIMeta?.alias || [],
    redirect: indexPHXV4FouQIMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/gameDetail/index.vue").then(m => m.default || m)
  },
  {
    name: indexPHXV4FouQIMeta?.name ?? "gameDetail___pl-pl",
    path: indexPHXV4FouQIMeta?.path ?? "/pl-pl/gameDetail",
    meta: indexPHXV4FouQIMeta || {},
    alias: indexPHXV4FouQIMeta?.alias || [],
    redirect: indexPHXV4FouQIMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/gameDetail/index.vue").then(m => m.default || m)
  },
  {
    name: indexPHXV4FouQIMeta?.name ?? "gameDetail___pt-br",
    path: indexPHXV4FouQIMeta?.path ?? "/pt-br/gameDetail",
    meta: indexPHXV4FouQIMeta || {},
    alias: indexPHXV4FouQIMeta?.alias || [],
    redirect: indexPHXV4FouQIMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/gameDetail/index.vue").then(m => m.default || m)
  },
  {
    name: indexPHXV4FouQIMeta?.name ?? "gameDetail___pt-pt",
    path: indexPHXV4FouQIMeta?.path ?? "/pt-pt/gameDetail",
    meta: indexPHXV4FouQIMeta || {},
    alias: indexPHXV4FouQIMeta?.alias || [],
    redirect: indexPHXV4FouQIMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/gameDetail/index.vue").then(m => m.default || m)
  },
  {
    name: indexPHXV4FouQIMeta?.name ?? "gameDetail___ru-ru",
    path: indexPHXV4FouQIMeta?.path ?? "/ru-ru/gameDetail",
    meta: indexPHXV4FouQIMeta || {},
    alias: indexPHXV4FouQIMeta?.alias || [],
    redirect: indexPHXV4FouQIMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/gameDetail/index.vue").then(m => m.default || m)
  },
  {
    name: indexPHXV4FouQIMeta?.name ?? "gameDetail___th-th",
    path: indexPHXV4FouQIMeta?.path ?? "/th-th/gameDetail",
    meta: indexPHXV4FouQIMeta || {},
    alias: indexPHXV4FouQIMeta?.alias || [],
    redirect: indexPHXV4FouQIMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/gameDetail/index.vue").then(m => m.default || m)
  },
  {
    name: indexPHXV4FouQIMeta?.name ?? "gameDetail___tr-tr",
    path: indexPHXV4FouQIMeta?.path ?? "/tr-tr/gameDetail",
    meta: indexPHXV4FouQIMeta || {},
    alias: indexPHXV4FouQIMeta?.alias || [],
    redirect: indexPHXV4FouQIMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/gameDetail/index.vue").then(m => m.default || m)
  },
  {
    name: indexPHXV4FouQIMeta?.name ?? "gameDetail___uk-ua",
    path: indexPHXV4FouQIMeta?.path ?? "/uk-ua/gameDetail",
    meta: indexPHXV4FouQIMeta || {},
    alias: indexPHXV4FouQIMeta?.alias || [],
    redirect: indexPHXV4FouQIMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/gameDetail/index.vue").then(m => m.default || m)
  },
  {
    name: indexPHXV4FouQIMeta?.name ?? "gameDetail___vi-vn",
    path: indexPHXV4FouQIMeta?.path ?? "/vi-vn/gameDetail",
    meta: indexPHXV4FouQIMeta || {},
    alias: indexPHXV4FouQIMeta?.alias || [],
    redirect: indexPHXV4FouQIMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/gameDetail/index.vue").then(m => m.default || m)
  },
  {
    name: indexPHXV4FouQIMeta?.name ?? "gameDetail___zh-cn",
    path: indexPHXV4FouQIMeta?.path ?? "/zh-cn/gameDetail",
    meta: indexPHXV4FouQIMeta || {},
    alias: indexPHXV4FouQIMeta?.alias || [],
    redirect: indexPHXV4FouQIMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/gameDetail/index.vue").then(m => m.default || m)
  },
  {
    name: indexPHXV4FouQIMeta?.name ?? "gameDetail___zh-tw",
    path: indexPHXV4FouQIMeta?.path ?? "/zh-tw/gameDetail",
    meta: indexPHXV4FouQIMeta || {},
    alias: indexPHXV4FouQIMeta?.alias || [],
    redirect: indexPHXV4FouQIMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/gameDetail/index.vue").then(m => m.default || m)
  },
  {
    name: indexUdz3PTn7uaMeta?.name ?? "gameDetailPC___ar-sa",
    path: indexUdz3PTn7uaMeta?.path ?? "/ar-sa/gameDetailPC",
    meta: indexUdz3PTn7uaMeta || {},
    alias: indexUdz3PTn7uaMeta?.alias || [],
    redirect: indexUdz3PTn7uaMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/gameDetailPC/index.vue").then(m => m.default || m)
  },
  {
    name: indexUdz3PTn7uaMeta?.name ?? "gameDetailPC___de-de",
    path: indexUdz3PTn7uaMeta?.path ?? "/de-de/gameDetailPC",
    meta: indexUdz3PTn7uaMeta || {},
    alias: indexUdz3PTn7uaMeta?.alias || [],
    redirect: indexUdz3PTn7uaMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/gameDetailPC/index.vue").then(m => m.default || m)
  },
  {
    name: indexUdz3PTn7uaMeta?.name ?? "gameDetailPC___en-us",
    path: indexUdz3PTn7uaMeta?.path ?? "/gameDetailPC",
    meta: indexUdz3PTn7uaMeta || {},
    alias: indexUdz3PTn7uaMeta?.alias || [],
    redirect: indexUdz3PTn7uaMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/gameDetailPC/index.vue").then(m => m.default || m)
  },
  {
    name: indexUdz3PTn7uaMeta?.name ?? "gameDetailPC___es-es",
    path: indexUdz3PTn7uaMeta?.path ?? "/es-es/gameDetailPC",
    meta: indexUdz3PTn7uaMeta || {},
    alias: indexUdz3PTn7uaMeta?.alias || [],
    redirect: indexUdz3PTn7uaMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/gameDetailPC/index.vue").then(m => m.default || m)
  },
  {
    name: indexUdz3PTn7uaMeta?.name ?? "gameDetailPC___fr-fr",
    path: indexUdz3PTn7uaMeta?.path ?? "/fr-fr/gameDetailPC",
    meta: indexUdz3PTn7uaMeta || {},
    alias: indexUdz3PTn7uaMeta?.alias || [],
    redirect: indexUdz3PTn7uaMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/gameDetailPC/index.vue").then(m => m.default || m)
  },
  {
    name: indexUdz3PTn7uaMeta?.name ?? "gameDetailPC___id-id",
    path: indexUdz3PTn7uaMeta?.path ?? "/id-id/gameDetailPC",
    meta: indexUdz3PTn7uaMeta || {},
    alias: indexUdz3PTn7uaMeta?.alias || [],
    redirect: indexUdz3PTn7uaMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/gameDetailPC/index.vue").then(m => m.default || m)
  },
  {
    name: indexUdz3PTn7uaMeta?.name ?? "gameDetailPC___it-it",
    path: indexUdz3PTn7uaMeta?.path ?? "/it-it/gameDetailPC",
    meta: indexUdz3PTn7uaMeta || {},
    alias: indexUdz3PTn7uaMeta?.alias || [],
    redirect: indexUdz3PTn7uaMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/gameDetailPC/index.vue").then(m => m.default || m)
  },
  {
    name: indexUdz3PTn7uaMeta?.name ?? "gameDetailPC___ja-jp",
    path: indexUdz3PTn7uaMeta?.path ?? "/ja-jp/gameDetailPC",
    meta: indexUdz3PTn7uaMeta || {},
    alias: indexUdz3PTn7uaMeta?.alias || [],
    redirect: indexUdz3PTn7uaMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/gameDetailPC/index.vue").then(m => m.default || m)
  },
  {
    name: indexUdz3PTn7uaMeta?.name ?? "gameDetailPC___ko-kr",
    path: indexUdz3PTn7uaMeta?.path ?? "/ko-kr/gameDetailPC",
    meta: indexUdz3PTn7uaMeta || {},
    alias: indexUdz3PTn7uaMeta?.alias || [],
    redirect: indexUdz3PTn7uaMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/gameDetailPC/index.vue").then(m => m.default || m)
  },
  {
    name: indexUdz3PTn7uaMeta?.name ?? "gameDetailPC___pl-pl",
    path: indexUdz3PTn7uaMeta?.path ?? "/pl-pl/gameDetailPC",
    meta: indexUdz3PTn7uaMeta || {},
    alias: indexUdz3PTn7uaMeta?.alias || [],
    redirect: indexUdz3PTn7uaMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/gameDetailPC/index.vue").then(m => m.default || m)
  },
  {
    name: indexUdz3PTn7uaMeta?.name ?? "gameDetailPC___pt-br",
    path: indexUdz3PTn7uaMeta?.path ?? "/pt-br/gameDetailPC",
    meta: indexUdz3PTn7uaMeta || {},
    alias: indexUdz3PTn7uaMeta?.alias || [],
    redirect: indexUdz3PTn7uaMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/gameDetailPC/index.vue").then(m => m.default || m)
  },
  {
    name: indexUdz3PTn7uaMeta?.name ?? "gameDetailPC___pt-pt",
    path: indexUdz3PTn7uaMeta?.path ?? "/pt-pt/gameDetailPC",
    meta: indexUdz3PTn7uaMeta || {},
    alias: indexUdz3PTn7uaMeta?.alias || [],
    redirect: indexUdz3PTn7uaMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/gameDetailPC/index.vue").then(m => m.default || m)
  },
  {
    name: indexUdz3PTn7uaMeta?.name ?? "gameDetailPC___ru-ru",
    path: indexUdz3PTn7uaMeta?.path ?? "/ru-ru/gameDetailPC",
    meta: indexUdz3PTn7uaMeta || {},
    alias: indexUdz3PTn7uaMeta?.alias || [],
    redirect: indexUdz3PTn7uaMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/gameDetailPC/index.vue").then(m => m.default || m)
  },
  {
    name: indexUdz3PTn7uaMeta?.name ?? "gameDetailPC___th-th",
    path: indexUdz3PTn7uaMeta?.path ?? "/th-th/gameDetailPC",
    meta: indexUdz3PTn7uaMeta || {},
    alias: indexUdz3PTn7uaMeta?.alias || [],
    redirect: indexUdz3PTn7uaMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/gameDetailPC/index.vue").then(m => m.default || m)
  },
  {
    name: indexUdz3PTn7uaMeta?.name ?? "gameDetailPC___tr-tr",
    path: indexUdz3PTn7uaMeta?.path ?? "/tr-tr/gameDetailPC",
    meta: indexUdz3PTn7uaMeta || {},
    alias: indexUdz3PTn7uaMeta?.alias || [],
    redirect: indexUdz3PTn7uaMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/gameDetailPC/index.vue").then(m => m.default || m)
  },
  {
    name: indexUdz3PTn7uaMeta?.name ?? "gameDetailPC___uk-ua",
    path: indexUdz3PTn7uaMeta?.path ?? "/uk-ua/gameDetailPC",
    meta: indexUdz3PTn7uaMeta || {},
    alias: indexUdz3PTn7uaMeta?.alias || [],
    redirect: indexUdz3PTn7uaMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/gameDetailPC/index.vue").then(m => m.default || m)
  },
  {
    name: indexUdz3PTn7uaMeta?.name ?? "gameDetailPC___vi-vn",
    path: indexUdz3PTn7uaMeta?.path ?? "/vi-vn/gameDetailPC",
    meta: indexUdz3PTn7uaMeta || {},
    alias: indexUdz3PTn7uaMeta?.alias || [],
    redirect: indexUdz3PTn7uaMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/gameDetailPC/index.vue").then(m => m.default || m)
  },
  {
    name: indexUdz3PTn7uaMeta?.name ?? "gameDetailPC___zh-cn",
    path: indexUdz3PTn7uaMeta?.path ?? "/zh-cn/gameDetailPC",
    meta: indexUdz3PTn7uaMeta || {},
    alias: indexUdz3PTn7uaMeta?.alias || [],
    redirect: indexUdz3PTn7uaMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/gameDetailPC/index.vue").then(m => m.default || m)
  },
  {
    name: indexUdz3PTn7uaMeta?.name ?? "gameDetailPC___zh-tw",
    path: indexUdz3PTn7uaMeta?.path ?? "/zh-tw/gameDetailPC",
    meta: indexUdz3PTn7uaMeta || {},
    alias: indexUdz3PTn7uaMeta?.alias || [],
    redirect: indexUdz3PTn7uaMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/gameDetailPC/index.vue").then(m => m.default || m)
  },
  {
    name: indexG1uJjkYeVVMeta?.name ?? "index___ar-sa",
    path: indexG1uJjkYeVVMeta?.path ?? "/ar-sa",
    meta: indexG1uJjkYeVVMeta || {},
    alias: indexG1uJjkYeVVMeta?.alias || [],
    redirect: indexG1uJjkYeVVMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexG1uJjkYeVVMeta?.name ?? "index___de-de",
    path: indexG1uJjkYeVVMeta?.path ?? "/de-de",
    meta: indexG1uJjkYeVVMeta || {},
    alias: indexG1uJjkYeVVMeta?.alias || [],
    redirect: indexG1uJjkYeVVMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexG1uJjkYeVVMeta?.name ?? "index___en-us",
    path: indexG1uJjkYeVVMeta?.path ?? "/",
    meta: indexG1uJjkYeVVMeta || {},
    alias: indexG1uJjkYeVVMeta?.alias || [],
    redirect: indexG1uJjkYeVVMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexG1uJjkYeVVMeta?.name ?? "index___es-es",
    path: indexG1uJjkYeVVMeta?.path ?? "/es-es",
    meta: indexG1uJjkYeVVMeta || {},
    alias: indexG1uJjkYeVVMeta?.alias || [],
    redirect: indexG1uJjkYeVVMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexG1uJjkYeVVMeta?.name ?? "index___fr-fr",
    path: indexG1uJjkYeVVMeta?.path ?? "/fr-fr",
    meta: indexG1uJjkYeVVMeta || {},
    alias: indexG1uJjkYeVVMeta?.alias || [],
    redirect: indexG1uJjkYeVVMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexG1uJjkYeVVMeta?.name ?? "index___id-id",
    path: indexG1uJjkYeVVMeta?.path ?? "/id-id",
    meta: indexG1uJjkYeVVMeta || {},
    alias: indexG1uJjkYeVVMeta?.alias || [],
    redirect: indexG1uJjkYeVVMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexG1uJjkYeVVMeta?.name ?? "index___it-it",
    path: indexG1uJjkYeVVMeta?.path ?? "/it-it",
    meta: indexG1uJjkYeVVMeta || {},
    alias: indexG1uJjkYeVVMeta?.alias || [],
    redirect: indexG1uJjkYeVVMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexG1uJjkYeVVMeta?.name ?? "index___ja-jp",
    path: indexG1uJjkYeVVMeta?.path ?? "/ja-jp",
    meta: indexG1uJjkYeVVMeta || {},
    alias: indexG1uJjkYeVVMeta?.alias || [],
    redirect: indexG1uJjkYeVVMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexG1uJjkYeVVMeta?.name ?? "index___ko-kr",
    path: indexG1uJjkYeVVMeta?.path ?? "/ko-kr",
    meta: indexG1uJjkYeVVMeta || {},
    alias: indexG1uJjkYeVVMeta?.alias || [],
    redirect: indexG1uJjkYeVVMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexG1uJjkYeVVMeta?.name ?? "index___pl-pl",
    path: indexG1uJjkYeVVMeta?.path ?? "/pl-pl",
    meta: indexG1uJjkYeVVMeta || {},
    alias: indexG1uJjkYeVVMeta?.alias || [],
    redirect: indexG1uJjkYeVVMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexG1uJjkYeVVMeta?.name ?? "index___pt-br",
    path: indexG1uJjkYeVVMeta?.path ?? "/pt-br",
    meta: indexG1uJjkYeVVMeta || {},
    alias: indexG1uJjkYeVVMeta?.alias || [],
    redirect: indexG1uJjkYeVVMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexG1uJjkYeVVMeta?.name ?? "index___pt-pt",
    path: indexG1uJjkYeVVMeta?.path ?? "/pt-pt",
    meta: indexG1uJjkYeVVMeta || {},
    alias: indexG1uJjkYeVVMeta?.alias || [],
    redirect: indexG1uJjkYeVVMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexG1uJjkYeVVMeta?.name ?? "index___ru-ru",
    path: indexG1uJjkYeVVMeta?.path ?? "/ru-ru",
    meta: indexG1uJjkYeVVMeta || {},
    alias: indexG1uJjkYeVVMeta?.alias || [],
    redirect: indexG1uJjkYeVVMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexG1uJjkYeVVMeta?.name ?? "index___th-th",
    path: indexG1uJjkYeVVMeta?.path ?? "/th-th",
    meta: indexG1uJjkYeVVMeta || {},
    alias: indexG1uJjkYeVVMeta?.alias || [],
    redirect: indexG1uJjkYeVVMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexG1uJjkYeVVMeta?.name ?? "index___tr-tr",
    path: indexG1uJjkYeVVMeta?.path ?? "/tr-tr",
    meta: indexG1uJjkYeVVMeta || {},
    alias: indexG1uJjkYeVVMeta?.alias || [],
    redirect: indexG1uJjkYeVVMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexG1uJjkYeVVMeta?.name ?? "index___uk-ua",
    path: indexG1uJjkYeVVMeta?.path ?? "/uk-ua",
    meta: indexG1uJjkYeVVMeta || {},
    alias: indexG1uJjkYeVVMeta?.alias || [],
    redirect: indexG1uJjkYeVVMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexG1uJjkYeVVMeta?.name ?? "index___vi-vn",
    path: indexG1uJjkYeVVMeta?.path ?? "/vi-vn",
    meta: indexG1uJjkYeVVMeta || {},
    alias: indexG1uJjkYeVVMeta?.alias || [],
    redirect: indexG1uJjkYeVVMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexG1uJjkYeVVMeta?.name ?? "index___zh-cn",
    path: indexG1uJjkYeVVMeta?.path ?? "/zh-cn",
    meta: indexG1uJjkYeVVMeta || {},
    alias: indexG1uJjkYeVVMeta?.alias || [],
    redirect: indexG1uJjkYeVVMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexG1uJjkYeVVMeta?.name ?? "index___zh-tw",
    path: indexG1uJjkYeVVMeta?.path ?? "/zh-tw",
    meta: indexG1uJjkYeVVMeta || {},
    alias: indexG1uJjkYeVVMeta?.alias || [],
    redirect: indexG1uJjkYeVVMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexpNrYMj1xE3Meta?.name ?? "login___ar-sa",
    path: indexpNrYMj1xE3Meta?.path ?? "/ar-sa/login",
    meta: indexpNrYMj1xE3Meta || {},
    alias: indexpNrYMj1xE3Meta?.alias || [],
    redirect: indexpNrYMj1xE3Meta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexpNrYMj1xE3Meta?.name ?? "login___de-de",
    path: indexpNrYMj1xE3Meta?.path ?? "/de-de/login",
    meta: indexpNrYMj1xE3Meta || {},
    alias: indexpNrYMj1xE3Meta?.alias || [],
    redirect: indexpNrYMj1xE3Meta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexpNrYMj1xE3Meta?.name ?? "login___en-us",
    path: indexpNrYMj1xE3Meta?.path ?? "/login",
    meta: indexpNrYMj1xE3Meta || {},
    alias: indexpNrYMj1xE3Meta?.alias || [],
    redirect: indexpNrYMj1xE3Meta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexpNrYMj1xE3Meta?.name ?? "login___es-es",
    path: indexpNrYMj1xE3Meta?.path ?? "/es-es/login",
    meta: indexpNrYMj1xE3Meta || {},
    alias: indexpNrYMj1xE3Meta?.alias || [],
    redirect: indexpNrYMj1xE3Meta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexpNrYMj1xE3Meta?.name ?? "login___fr-fr",
    path: indexpNrYMj1xE3Meta?.path ?? "/fr-fr/login",
    meta: indexpNrYMj1xE3Meta || {},
    alias: indexpNrYMj1xE3Meta?.alias || [],
    redirect: indexpNrYMj1xE3Meta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexpNrYMj1xE3Meta?.name ?? "login___id-id",
    path: indexpNrYMj1xE3Meta?.path ?? "/id-id/login",
    meta: indexpNrYMj1xE3Meta || {},
    alias: indexpNrYMj1xE3Meta?.alias || [],
    redirect: indexpNrYMj1xE3Meta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexpNrYMj1xE3Meta?.name ?? "login___it-it",
    path: indexpNrYMj1xE3Meta?.path ?? "/it-it/login",
    meta: indexpNrYMj1xE3Meta || {},
    alias: indexpNrYMj1xE3Meta?.alias || [],
    redirect: indexpNrYMj1xE3Meta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexpNrYMj1xE3Meta?.name ?? "login___ja-jp",
    path: indexpNrYMj1xE3Meta?.path ?? "/ja-jp/login",
    meta: indexpNrYMj1xE3Meta || {},
    alias: indexpNrYMj1xE3Meta?.alias || [],
    redirect: indexpNrYMj1xE3Meta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexpNrYMj1xE3Meta?.name ?? "login___ko-kr",
    path: indexpNrYMj1xE3Meta?.path ?? "/ko-kr/login",
    meta: indexpNrYMj1xE3Meta || {},
    alias: indexpNrYMj1xE3Meta?.alias || [],
    redirect: indexpNrYMj1xE3Meta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexpNrYMj1xE3Meta?.name ?? "login___pl-pl",
    path: indexpNrYMj1xE3Meta?.path ?? "/pl-pl/login",
    meta: indexpNrYMj1xE3Meta || {},
    alias: indexpNrYMj1xE3Meta?.alias || [],
    redirect: indexpNrYMj1xE3Meta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexpNrYMj1xE3Meta?.name ?? "login___pt-br",
    path: indexpNrYMj1xE3Meta?.path ?? "/pt-br/login",
    meta: indexpNrYMj1xE3Meta || {},
    alias: indexpNrYMj1xE3Meta?.alias || [],
    redirect: indexpNrYMj1xE3Meta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexpNrYMj1xE3Meta?.name ?? "login___pt-pt",
    path: indexpNrYMj1xE3Meta?.path ?? "/pt-pt/login",
    meta: indexpNrYMj1xE3Meta || {},
    alias: indexpNrYMj1xE3Meta?.alias || [],
    redirect: indexpNrYMj1xE3Meta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexpNrYMj1xE3Meta?.name ?? "login___ru-ru",
    path: indexpNrYMj1xE3Meta?.path ?? "/ru-ru/login",
    meta: indexpNrYMj1xE3Meta || {},
    alias: indexpNrYMj1xE3Meta?.alias || [],
    redirect: indexpNrYMj1xE3Meta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexpNrYMj1xE3Meta?.name ?? "login___th-th",
    path: indexpNrYMj1xE3Meta?.path ?? "/th-th/login",
    meta: indexpNrYMj1xE3Meta || {},
    alias: indexpNrYMj1xE3Meta?.alias || [],
    redirect: indexpNrYMj1xE3Meta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexpNrYMj1xE3Meta?.name ?? "login___tr-tr",
    path: indexpNrYMj1xE3Meta?.path ?? "/tr-tr/login",
    meta: indexpNrYMj1xE3Meta || {},
    alias: indexpNrYMj1xE3Meta?.alias || [],
    redirect: indexpNrYMj1xE3Meta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexpNrYMj1xE3Meta?.name ?? "login___uk-ua",
    path: indexpNrYMj1xE3Meta?.path ?? "/uk-ua/login",
    meta: indexpNrYMj1xE3Meta || {},
    alias: indexpNrYMj1xE3Meta?.alias || [],
    redirect: indexpNrYMj1xE3Meta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexpNrYMj1xE3Meta?.name ?? "login___vi-vn",
    path: indexpNrYMj1xE3Meta?.path ?? "/vi-vn/login",
    meta: indexpNrYMj1xE3Meta || {},
    alias: indexpNrYMj1xE3Meta?.alias || [],
    redirect: indexpNrYMj1xE3Meta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexpNrYMj1xE3Meta?.name ?? "login___zh-cn",
    path: indexpNrYMj1xE3Meta?.path ?? "/zh-cn/login",
    meta: indexpNrYMj1xE3Meta || {},
    alias: indexpNrYMj1xE3Meta?.alias || [],
    redirect: indexpNrYMj1xE3Meta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexpNrYMj1xE3Meta?.name ?? "login___zh-tw",
    path: indexpNrYMj1xE3Meta?.path ?? "/zh-tw/login",
    meta: indexpNrYMj1xE3Meta || {},
    alias: indexpNrYMj1xE3Meta?.alias || [],
    redirect: indexpNrYMj1xE3Meta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexg0IwFtkpDoMeta?.name ?? "loginPC___ar-sa",
    path: indexg0IwFtkpDoMeta?.path ?? "/ar-sa/loginPC",
    meta: indexg0IwFtkpDoMeta || {},
    alias: indexg0IwFtkpDoMeta?.alias || [],
    redirect: indexg0IwFtkpDoMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/loginPC/index.vue").then(m => m.default || m)
  },
  {
    name: indexg0IwFtkpDoMeta?.name ?? "loginPC___de-de",
    path: indexg0IwFtkpDoMeta?.path ?? "/de-de/loginPC",
    meta: indexg0IwFtkpDoMeta || {},
    alias: indexg0IwFtkpDoMeta?.alias || [],
    redirect: indexg0IwFtkpDoMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/loginPC/index.vue").then(m => m.default || m)
  },
  {
    name: indexg0IwFtkpDoMeta?.name ?? "loginPC___en-us",
    path: indexg0IwFtkpDoMeta?.path ?? "/loginPC",
    meta: indexg0IwFtkpDoMeta || {},
    alias: indexg0IwFtkpDoMeta?.alias || [],
    redirect: indexg0IwFtkpDoMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/loginPC/index.vue").then(m => m.default || m)
  },
  {
    name: indexg0IwFtkpDoMeta?.name ?? "loginPC___es-es",
    path: indexg0IwFtkpDoMeta?.path ?? "/es-es/loginPC",
    meta: indexg0IwFtkpDoMeta || {},
    alias: indexg0IwFtkpDoMeta?.alias || [],
    redirect: indexg0IwFtkpDoMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/loginPC/index.vue").then(m => m.default || m)
  },
  {
    name: indexg0IwFtkpDoMeta?.name ?? "loginPC___fr-fr",
    path: indexg0IwFtkpDoMeta?.path ?? "/fr-fr/loginPC",
    meta: indexg0IwFtkpDoMeta || {},
    alias: indexg0IwFtkpDoMeta?.alias || [],
    redirect: indexg0IwFtkpDoMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/loginPC/index.vue").then(m => m.default || m)
  },
  {
    name: indexg0IwFtkpDoMeta?.name ?? "loginPC___id-id",
    path: indexg0IwFtkpDoMeta?.path ?? "/id-id/loginPC",
    meta: indexg0IwFtkpDoMeta || {},
    alias: indexg0IwFtkpDoMeta?.alias || [],
    redirect: indexg0IwFtkpDoMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/loginPC/index.vue").then(m => m.default || m)
  },
  {
    name: indexg0IwFtkpDoMeta?.name ?? "loginPC___it-it",
    path: indexg0IwFtkpDoMeta?.path ?? "/it-it/loginPC",
    meta: indexg0IwFtkpDoMeta || {},
    alias: indexg0IwFtkpDoMeta?.alias || [],
    redirect: indexg0IwFtkpDoMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/loginPC/index.vue").then(m => m.default || m)
  },
  {
    name: indexg0IwFtkpDoMeta?.name ?? "loginPC___ja-jp",
    path: indexg0IwFtkpDoMeta?.path ?? "/ja-jp/loginPC",
    meta: indexg0IwFtkpDoMeta || {},
    alias: indexg0IwFtkpDoMeta?.alias || [],
    redirect: indexg0IwFtkpDoMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/loginPC/index.vue").then(m => m.default || m)
  },
  {
    name: indexg0IwFtkpDoMeta?.name ?? "loginPC___ko-kr",
    path: indexg0IwFtkpDoMeta?.path ?? "/ko-kr/loginPC",
    meta: indexg0IwFtkpDoMeta || {},
    alias: indexg0IwFtkpDoMeta?.alias || [],
    redirect: indexg0IwFtkpDoMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/loginPC/index.vue").then(m => m.default || m)
  },
  {
    name: indexg0IwFtkpDoMeta?.name ?? "loginPC___pl-pl",
    path: indexg0IwFtkpDoMeta?.path ?? "/pl-pl/loginPC",
    meta: indexg0IwFtkpDoMeta || {},
    alias: indexg0IwFtkpDoMeta?.alias || [],
    redirect: indexg0IwFtkpDoMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/loginPC/index.vue").then(m => m.default || m)
  },
  {
    name: indexg0IwFtkpDoMeta?.name ?? "loginPC___pt-br",
    path: indexg0IwFtkpDoMeta?.path ?? "/pt-br/loginPC",
    meta: indexg0IwFtkpDoMeta || {},
    alias: indexg0IwFtkpDoMeta?.alias || [],
    redirect: indexg0IwFtkpDoMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/loginPC/index.vue").then(m => m.default || m)
  },
  {
    name: indexg0IwFtkpDoMeta?.name ?? "loginPC___pt-pt",
    path: indexg0IwFtkpDoMeta?.path ?? "/pt-pt/loginPC",
    meta: indexg0IwFtkpDoMeta || {},
    alias: indexg0IwFtkpDoMeta?.alias || [],
    redirect: indexg0IwFtkpDoMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/loginPC/index.vue").then(m => m.default || m)
  },
  {
    name: indexg0IwFtkpDoMeta?.name ?? "loginPC___ru-ru",
    path: indexg0IwFtkpDoMeta?.path ?? "/ru-ru/loginPC",
    meta: indexg0IwFtkpDoMeta || {},
    alias: indexg0IwFtkpDoMeta?.alias || [],
    redirect: indexg0IwFtkpDoMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/loginPC/index.vue").then(m => m.default || m)
  },
  {
    name: indexg0IwFtkpDoMeta?.name ?? "loginPC___th-th",
    path: indexg0IwFtkpDoMeta?.path ?? "/th-th/loginPC",
    meta: indexg0IwFtkpDoMeta || {},
    alias: indexg0IwFtkpDoMeta?.alias || [],
    redirect: indexg0IwFtkpDoMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/loginPC/index.vue").then(m => m.default || m)
  },
  {
    name: indexg0IwFtkpDoMeta?.name ?? "loginPC___tr-tr",
    path: indexg0IwFtkpDoMeta?.path ?? "/tr-tr/loginPC",
    meta: indexg0IwFtkpDoMeta || {},
    alias: indexg0IwFtkpDoMeta?.alias || [],
    redirect: indexg0IwFtkpDoMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/loginPC/index.vue").then(m => m.default || m)
  },
  {
    name: indexg0IwFtkpDoMeta?.name ?? "loginPC___uk-ua",
    path: indexg0IwFtkpDoMeta?.path ?? "/uk-ua/loginPC",
    meta: indexg0IwFtkpDoMeta || {},
    alias: indexg0IwFtkpDoMeta?.alias || [],
    redirect: indexg0IwFtkpDoMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/loginPC/index.vue").then(m => m.default || m)
  },
  {
    name: indexg0IwFtkpDoMeta?.name ?? "loginPC___vi-vn",
    path: indexg0IwFtkpDoMeta?.path ?? "/vi-vn/loginPC",
    meta: indexg0IwFtkpDoMeta || {},
    alias: indexg0IwFtkpDoMeta?.alias || [],
    redirect: indexg0IwFtkpDoMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/loginPC/index.vue").then(m => m.default || m)
  },
  {
    name: indexg0IwFtkpDoMeta?.name ?? "loginPC___zh-cn",
    path: indexg0IwFtkpDoMeta?.path ?? "/zh-cn/loginPC",
    meta: indexg0IwFtkpDoMeta || {},
    alias: indexg0IwFtkpDoMeta?.alias || [],
    redirect: indexg0IwFtkpDoMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/loginPC/index.vue").then(m => m.default || m)
  },
  {
    name: indexg0IwFtkpDoMeta?.name ?? "loginPC___zh-tw",
    path: indexg0IwFtkpDoMeta?.path ?? "/zh-tw/loginPC",
    meta: indexg0IwFtkpDoMeta || {},
    alias: indexg0IwFtkpDoMeta?.alias || [],
    redirect: indexg0IwFtkpDoMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/loginPC/index.vue").then(m => m.default || m)
  },
  {
    name: index1fJstjI9mhMeta?.name ?? "myGame___ar-sa",
    path: index1fJstjI9mhMeta?.path ?? "/ar-sa/myGame",
    meta: index1fJstjI9mhMeta || {},
    alias: index1fJstjI9mhMeta?.alias || [],
    redirect: index1fJstjI9mhMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/myGame/index.vue").then(m => m.default || m)
  },
  {
    name: index1fJstjI9mhMeta?.name ?? "myGame___de-de",
    path: index1fJstjI9mhMeta?.path ?? "/de-de/myGame",
    meta: index1fJstjI9mhMeta || {},
    alias: index1fJstjI9mhMeta?.alias || [],
    redirect: index1fJstjI9mhMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/myGame/index.vue").then(m => m.default || m)
  },
  {
    name: index1fJstjI9mhMeta?.name ?? "myGame___en-us",
    path: index1fJstjI9mhMeta?.path ?? "/myGame",
    meta: index1fJstjI9mhMeta || {},
    alias: index1fJstjI9mhMeta?.alias || [],
    redirect: index1fJstjI9mhMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/myGame/index.vue").then(m => m.default || m)
  },
  {
    name: index1fJstjI9mhMeta?.name ?? "myGame___es-es",
    path: index1fJstjI9mhMeta?.path ?? "/es-es/myGame",
    meta: index1fJstjI9mhMeta || {},
    alias: index1fJstjI9mhMeta?.alias || [],
    redirect: index1fJstjI9mhMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/myGame/index.vue").then(m => m.default || m)
  },
  {
    name: index1fJstjI9mhMeta?.name ?? "myGame___fr-fr",
    path: index1fJstjI9mhMeta?.path ?? "/fr-fr/myGame",
    meta: index1fJstjI9mhMeta || {},
    alias: index1fJstjI9mhMeta?.alias || [],
    redirect: index1fJstjI9mhMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/myGame/index.vue").then(m => m.default || m)
  },
  {
    name: index1fJstjI9mhMeta?.name ?? "myGame___id-id",
    path: index1fJstjI9mhMeta?.path ?? "/id-id/myGame",
    meta: index1fJstjI9mhMeta || {},
    alias: index1fJstjI9mhMeta?.alias || [],
    redirect: index1fJstjI9mhMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/myGame/index.vue").then(m => m.default || m)
  },
  {
    name: index1fJstjI9mhMeta?.name ?? "myGame___it-it",
    path: index1fJstjI9mhMeta?.path ?? "/it-it/myGame",
    meta: index1fJstjI9mhMeta || {},
    alias: index1fJstjI9mhMeta?.alias || [],
    redirect: index1fJstjI9mhMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/myGame/index.vue").then(m => m.default || m)
  },
  {
    name: index1fJstjI9mhMeta?.name ?? "myGame___ja-jp",
    path: index1fJstjI9mhMeta?.path ?? "/ja-jp/myGame",
    meta: index1fJstjI9mhMeta || {},
    alias: index1fJstjI9mhMeta?.alias || [],
    redirect: index1fJstjI9mhMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/myGame/index.vue").then(m => m.default || m)
  },
  {
    name: index1fJstjI9mhMeta?.name ?? "myGame___ko-kr",
    path: index1fJstjI9mhMeta?.path ?? "/ko-kr/myGame",
    meta: index1fJstjI9mhMeta || {},
    alias: index1fJstjI9mhMeta?.alias || [],
    redirect: index1fJstjI9mhMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/myGame/index.vue").then(m => m.default || m)
  },
  {
    name: index1fJstjI9mhMeta?.name ?? "myGame___pl-pl",
    path: index1fJstjI9mhMeta?.path ?? "/pl-pl/myGame",
    meta: index1fJstjI9mhMeta || {},
    alias: index1fJstjI9mhMeta?.alias || [],
    redirect: index1fJstjI9mhMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/myGame/index.vue").then(m => m.default || m)
  },
  {
    name: index1fJstjI9mhMeta?.name ?? "myGame___pt-br",
    path: index1fJstjI9mhMeta?.path ?? "/pt-br/myGame",
    meta: index1fJstjI9mhMeta || {},
    alias: index1fJstjI9mhMeta?.alias || [],
    redirect: index1fJstjI9mhMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/myGame/index.vue").then(m => m.default || m)
  },
  {
    name: index1fJstjI9mhMeta?.name ?? "myGame___pt-pt",
    path: index1fJstjI9mhMeta?.path ?? "/pt-pt/myGame",
    meta: index1fJstjI9mhMeta || {},
    alias: index1fJstjI9mhMeta?.alias || [],
    redirect: index1fJstjI9mhMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/myGame/index.vue").then(m => m.default || m)
  },
  {
    name: index1fJstjI9mhMeta?.name ?? "myGame___ru-ru",
    path: index1fJstjI9mhMeta?.path ?? "/ru-ru/myGame",
    meta: index1fJstjI9mhMeta || {},
    alias: index1fJstjI9mhMeta?.alias || [],
    redirect: index1fJstjI9mhMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/myGame/index.vue").then(m => m.default || m)
  },
  {
    name: index1fJstjI9mhMeta?.name ?? "myGame___th-th",
    path: index1fJstjI9mhMeta?.path ?? "/th-th/myGame",
    meta: index1fJstjI9mhMeta || {},
    alias: index1fJstjI9mhMeta?.alias || [],
    redirect: index1fJstjI9mhMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/myGame/index.vue").then(m => m.default || m)
  },
  {
    name: index1fJstjI9mhMeta?.name ?? "myGame___tr-tr",
    path: index1fJstjI9mhMeta?.path ?? "/tr-tr/myGame",
    meta: index1fJstjI9mhMeta || {},
    alias: index1fJstjI9mhMeta?.alias || [],
    redirect: index1fJstjI9mhMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/myGame/index.vue").then(m => m.default || m)
  },
  {
    name: index1fJstjI9mhMeta?.name ?? "myGame___uk-ua",
    path: index1fJstjI9mhMeta?.path ?? "/uk-ua/myGame",
    meta: index1fJstjI9mhMeta || {},
    alias: index1fJstjI9mhMeta?.alias || [],
    redirect: index1fJstjI9mhMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/myGame/index.vue").then(m => m.default || m)
  },
  {
    name: index1fJstjI9mhMeta?.name ?? "myGame___vi-vn",
    path: index1fJstjI9mhMeta?.path ?? "/vi-vn/myGame",
    meta: index1fJstjI9mhMeta || {},
    alias: index1fJstjI9mhMeta?.alias || [],
    redirect: index1fJstjI9mhMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/myGame/index.vue").then(m => m.default || m)
  },
  {
    name: index1fJstjI9mhMeta?.name ?? "myGame___zh-cn",
    path: index1fJstjI9mhMeta?.path ?? "/zh-cn/myGame",
    meta: index1fJstjI9mhMeta || {},
    alias: index1fJstjI9mhMeta?.alias || [],
    redirect: index1fJstjI9mhMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/myGame/index.vue").then(m => m.default || m)
  },
  {
    name: index1fJstjI9mhMeta?.name ?? "myGame___zh-tw",
    path: index1fJstjI9mhMeta?.path ?? "/zh-tw/myGame",
    meta: index1fJstjI9mhMeta || {},
    alias: index1fJstjI9mhMeta?.alias || [],
    redirect: index1fJstjI9mhMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/myGame/index.vue").then(m => m.default || m)
  },
  {
    name: indexHWTdV6KKsFMeta?.name ?? "register___ar-sa",
    path: indexHWTdV6KKsFMeta?.path ?? "/ar-sa/register",
    meta: indexHWTdV6KKsFMeta || {},
    alias: indexHWTdV6KKsFMeta?.alias || [],
    redirect: indexHWTdV6KKsFMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: indexHWTdV6KKsFMeta?.name ?? "register___de-de",
    path: indexHWTdV6KKsFMeta?.path ?? "/de-de/register",
    meta: indexHWTdV6KKsFMeta || {},
    alias: indexHWTdV6KKsFMeta?.alias || [],
    redirect: indexHWTdV6KKsFMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: indexHWTdV6KKsFMeta?.name ?? "register___en-us",
    path: indexHWTdV6KKsFMeta?.path ?? "/register",
    meta: indexHWTdV6KKsFMeta || {},
    alias: indexHWTdV6KKsFMeta?.alias || [],
    redirect: indexHWTdV6KKsFMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: indexHWTdV6KKsFMeta?.name ?? "register___es-es",
    path: indexHWTdV6KKsFMeta?.path ?? "/es-es/register",
    meta: indexHWTdV6KKsFMeta || {},
    alias: indexHWTdV6KKsFMeta?.alias || [],
    redirect: indexHWTdV6KKsFMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: indexHWTdV6KKsFMeta?.name ?? "register___fr-fr",
    path: indexHWTdV6KKsFMeta?.path ?? "/fr-fr/register",
    meta: indexHWTdV6KKsFMeta || {},
    alias: indexHWTdV6KKsFMeta?.alias || [],
    redirect: indexHWTdV6KKsFMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: indexHWTdV6KKsFMeta?.name ?? "register___id-id",
    path: indexHWTdV6KKsFMeta?.path ?? "/id-id/register",
    meta: indexHWTdV6KKsFMeta || {},
    alias: indexHWTdV6KKsFMeta?.alias || [],
    redirect: indexHWTdV6KKsFMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: indexHWTdV6KKsFMeta?.name ?? "register___it-it",
    path: indexHWTdV6KKsFMeta?.path ?? "/it-it/register",
    meta: indexHWTdV6KKsFMeta || {},
    alias: indexHWTdV6KKsFMeta?.alias || [],
    redirect: indexHWTdV6KKsFMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: indexHWTdV6KKsFMeta?.name ?? "register___ja-jp",
    path: indexHWTdV6KKsFMeta?.path ?? "/ja-jp/register",
    meta: indexHWTdV6KKsFMeta || {},
    alias: indexHWTdV6KKsFMeta?.alias || [],
    redirect: indexHWTdV6KKsFMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: indexHWTdV6KKsFMeta?.name ?? "register___ko-kr",
    path: indexHWTdV6KKsFMeta?.path ?? "/ko-kr/register",
    meta: indexHWTdV6KKsFMeta || {},
    alias: indexHWTdV6KKsFMeta?.alias || [],
    redirect: indexHWTdV6KKsFMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: indexHWTdV6KKsFMeta?.name ?? "register___pl-pl",
    path: indexHWTdV6KKsFMeta?.path ?? "/pl-pl/register",
    meta: indexHWTdV6KKsFMeta || {},
    alias: indexHWTdV6KKsFMeta?.alias || [],
    redirect: indexHWTdV6KKsFMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: indexHWTdV6KKsFMeta?.name ?? "register___pt-br",
    path: indexHWTdV6KKsFMeta?.path ?? "/pt-br/register",
    meta: indexHWTdV6KKsFMeta || {},
    alias: indexHWTdV6KKsFMeta?.alias || [],
    redirect: indexHWTdV6KKsFMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: indexHWTdV6KKsFMeta?.name ?? "register___pt-pt",
    path: indexHWTdV6KKsFMeta?.path ?? "/pt-pt/register",
    meta: indexHWTdV6KKsFMeta || {},
    alias: indexHWTdV6KKsFMeta?.alias || [],
    redirect: indexHWTdV6KKsFMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: indexHWTdV6KKsFMeta?.name ?? "register___ru-ru",
    path: indexHWTdV6KKsFMeta?.path ?? "/ru-ru/register",
    meta: indexHWTdV6KKsFMeta || {},
    alias: indexHWTdV6KKsFMeta?.alias || [],
    redirect: indexHWTdV6KKsFMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: indexHWTdV6KKsFMeta?.name ?? "register___th-th",
    path: indexHWTdV6KKsFMeta?.path ?? "/th-th/register",
    meta: indexHWTdV6KKsFMeta || {},
    alias: indexHWTdV6KKsFMeta?.alias || [],
    redirect: indexHWTdV6KKsFMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: indexHWTdV6KKsFMeta?.name ?? "register___tr-tr",
    path: indexHWTdV6KKsFMeta?.path ?? "/tr-tr/register",
    meta: indexHWTdV6KKsFMeta || {},
    alias: indexHWTdV6KKsFMeta?.alias || [],
    redirect: indexHWTdV6KKsFMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: indexHWTdV6KKsFMeta?.name ?? "register___uk-ua",
    path: indexHWTdV6KKsFMeta?.path ?? "/uk-ua/register",
    meta: indexHWTdV6KKsFMeta || {},
    alias: indexHWTdV6KKsFMeta?.alias || [],
    redirect: indexHWTdV6KKsFMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: indexHWTdV6KKsFMeta?.name ?? "register___vi-vn",
    path: indexHWTdV6KKsFMeta?.path ?? "/vi-vn/register",
    meta: indexHWTdV6KKsFMeta || {},
    alias: indexHWTdV6KKsFMeta?.alias || [],
    redirect: indexHWTdV6KKsFMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: indexHWTdV6KKsFMeta?.name ?? "register___zh-cn",
    path: indexHWTdV6KKsFMeta?.path ?? "/zh-cn/register",
    meta: indexHWTdV6KKsFMeta || {},
    alias: indexHWTdV6KKsFMeta?.alias || [],
    redirect: indexHWTdV6KKsFMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: indexHWTdV6KKsFMeta?.name ?? "register___zh-tw",
    path: indexHWTdV6KKsFMeta?.path ?? "/zh-tw/register",
    meta: indexHWTdV6KKsFMeta || {},
    alias: indexHWTdV6KKsFMeta?.alias || [],
    redirect: indexHWTdV6KKsFMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: indexKVtlRsqTe0Meta?.name ?? "register-registerCode___ar-sa",
    path: indexKVtlRsqTe0Meta?.path ?? "/ar-sa/register/registerCode",
    meta: indexKVtlRsqTe0Meta || {},
    alias: indexKVtlRsqTe0Meta?.alias || [],
    redirect: indexKVtlRsqTe0Meta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/register/registerCode/index.vue").then(m => m.default || m)
  },
  {
    name: indexKVtlRsqTe0Meta?.name ?? "register-registerCode___de-de",
    path: indexKVtlRsqTe0Meta?.path ?? "/de-de/register/registerCode",
    meta: indexKVtlRsqTe0Meta || {},
    alias: indexKVtlRsqTe0Meta?.alias || [],
    redirect: indexKVtlRsqTe0Meta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/register/registerCode/index.vue").then(m => m.default || m)
  },
  {
    name: indexKVtlRsqTe0Meta?.name ?? "register-registerCode___en-us",
    path: indexKVtlRsqTe0Meta?.path ?? "/register/registerCode",
    meta: indexKVtlRsqTe0Meta || {},
    alias: indexKVtlRsqTe0Meta?.alias || [],
    redirect: indexKVtlRsqTe0Meta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/register/registerCode/index.vue").then(m => m.default || m)
  },
  {
    name: indexKVtlRsqTe0Meta?.name ?? "register-registerCode___es-es",
    path: indexKVtlRsqTe0Meta?.path ?? "/es-es/register/registerCode",
    meta: indexKVtlRsqTe0Meta || {},
    alias: indexKVtlRsqTe0Meta?.alias || [],
    redirect: indexKVtlRsqTe0Meta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/register/registerCode/index.vue").then(m => m.default || m)
  },
  {
    name: indexKVtlRsqTe0Meta?.name ?? "register-registerCode___fr-fr",
    path: indexKVtlRsqTe0Meta?.path ?? "/fr-fr/register/registerCode",
    meta: indexKVtlRsqTe0Meta || {},
    alias: indexKVtlRsqTe0Meta?.alias || [],
    redirect: indexKVtlRsqTe0Meta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/register/registerCode/index.vue").then(m => m.default || m)
  },
  {
    name: indexKVtlRsqTe0Meta?.name ?? "register-registerCode___id-id",
    path: indexKVtlRsqTe0Meta?.path ?? "/id-id/register/registerCode",
    meta: indexKVtlRsqTe0Meta || {},
    alias: indexKVtlRsqTe0Meta?.alias || [],
    redirect: indexKVtlRsqTe0Meta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/register/registerCode/index.vue").then(m => m.default || m)
  },
  {
    name: indexKVtlRsqTe0Meta?.name ?? "register-registerCode___it-it",
    path: indexKVtlRsqTe0Meta?.path ?? "/it-it/register/registerCode",
    meta: indexKVtlRsqTe0Meta || {},
    alias: indexKVtlRsqTe0Meta?.alias || [],
    redirect: indexKVtlRsqTe0Meta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/register/registerCode/index.vue").then(m => m.default || m)
  },
  {
    name: indexKVtlRsqTe0Meta?.name ?? "register-registerCode___ja-jp",
    path: indexKVtlRsqTe0Meta?.path ?? "/ja-jp/register/registerCode",
    meta: indexKVtlRsqTe0Meta || {},
    alias: indexKVtlRsqTe0Meta?.alias || [],
    redirect: indexKVtlRsqTe0Meta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/register/registerCode/index.vue").then(m => m.default || m)
  },
  {
    name: indexKVtlRsqTe0Meta?.name ?? "register-registerCode___ko-kr",
    path: indexKVtlRsqTe0Meta?.path ?? "/ko-kr/register/registerCode",
    meta: indexKVtlRsqTe0Meta || {},
    alias: indexKVtlRsqTe0Meta?.alias || [],
    redirect: indexKVtlRsqTe0Meta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/register/registerCode/index.vue").then(m => m.default || m)
  },
  {
    name: indexKVtlRsqTe0Meta?.name ?? "register-registerCode___pl-pl",
    path: indexKVtlRsqTe0Meta?.path ?? "/pl-pl/register/registerCode",
    meta: indexKVtlRsqTe0Meta || {},
    alias: indexKVtlRsqTe0Meta?.alias || [],
    redirect: indexKVtlRsqTe0Meta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/register/registerCode/index.vue").then(m => m.default || m)
  },
  {
    name: indexKVtlRsqTe0Meta?.name ?? "register-registerCode___pt-br",
    path: indexKVtlRsqTe0Meta?.path ?? "/pt-br/register/registerCode",
    meta: indexKVtlRsqTe0Meta || {},
    alias: indexKVtlRsqTe0Meta?.alias || [],
    redirect: indexKVtlRsqTe0Meta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/register/registerCode/index.vue").then(m => m.default || m)
  },
  {
    name: indexKVtlRsqTe0Meta?.name ?? "register-registerCode___pt-pt",
    path: indexKVtlRsqTe0Meta?.path ?? "/pt-pt/register/registerCode",
    meta: indexKVtlRsqTe0Meta || {},
    alias: indexKVtlRsqTe0Meta?.alias || [],
    redirect: indexKVtlRsqTe0Meta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/register/registerCode/index.vue").then(m => m.default || m)
  },
  {
    name: indexKVtlRsqTe0Meta?.name ?? "register-registerCode___ru-ru",
    path: indexKVtlRsqTe0Meta?.path ?? "/ru-ru/register/registerCode",
    meta: indexKVtlRsqTe0Meta || {},
    alias: indexKVtlRsqTe0Meta?.alias || [],
    redirect: indexKVtlRsqTe0Meta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/register/registerCode/index.vue").then(m => m.default || m)
  },
  {
    name: indexKVtlRsqTe0Meta?.name ?? "register-registerCode___th-th",
    path: indexKVtlRsqTe0Meta?.path ?? "/th-th/register/registerCode",
    meta: indexKVtlRsqTe0Meta || {},
    alias: indexKVtlRsqTe0Meta?.alias || [],
    redirect: indexKVtlRsqTe0Meta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/register/registerCode/index.vue").then(m => m.default || m)
  },
  {
    name: indexKVtlRsqTe0Meta?.name ?? "register-registerCode___tr-tr",
    path: indexKVtlRsqTe0Meta?.path ?? "/tr-tr/register/registerCode",
    meta: indexKVtlRsqTe0Meta || {},
    alias: indexKVtlRsqTe0Meta?.alias || [],
    redirect: indexKVtlRsqTe0Meta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/register/registerCode/index.vue").then(m => m.default || m)
  },
  {
    name: indexKVtlRsqTe0Meta?.name ?? "register-registerCode___uk-ua",
    path: indexKVtlRsqTe0Meta?.path ?? "/uk-ua/register/registerCode",
    meta: indexKVtlRsqTe0Meta || {},
    alias: indexKVtlRsqTe0Meta?.alias || [],
    redirect: indexKVtlRsqTe0Meta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/register/registerCode/index.vue").then(m => m.default || m)
  },
  {
    name: indexKVtlRsqTe0Meta?.name ?? "register-registerCode___vi-vn",
    path: indexKVtlRsqTe0Meta?.path ?? "/vi-vn/register/registerCode",
    meta: indexKVtlRsqTe0Meta || {},
    alias: indexKVtlRsqTe0Meta?.alias || [],
    redirect: indexKVtlRsqTe0Meta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/register/registerCode/index.vue").then(m => m.default || m)
  },
  {
    name: indexKVtlRsqTe0Meta?.name ?? "register-registerCode___zh-cn",
    path: indexKVtlRsqTe0Meta?.path ?? "/zh-cn/register/registerCode",
    meta: indexKVtlRsqTe0Meta || {},
    alias: indexKVtlRsqTe0Meta?.alias || [],
    redirect: indexKVtlRsqTe0Meta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/register/registerCode/index.vue").then(m => m.default || m)
  },
  {
    name: indexKVtlRsqTe0Meta?.name ?? "register-registerCode___zh-tw",
    path: indexKVtlRsqTe0Meta?.path ?? "/zh-tw/register/registerCode",
    meta: indexKVtlRsqTe0Meta || {},
    alias: indexKVtlRsqTe0Meta?.alias || [],
    redirect: indexKVtlRsqTe0Meta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/register/registerCode/index.vue").then(m => m.default || m)
  },
  {
    name: indexZ6ZuKsKAuRMeta?.name ?? "register-registerSuccess___ar-sa",
    path: indexZ6ZuKsKAuRMeta?.path ?? "/ar-sa/register/registerSuccess",
    meta: indexZ6ZuKsKAuRMeta || {},
    alias: indexZ6ZuKsKAuRMeta?.alias || [],
    redirect: indexZ6ZuKsKAuRMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/register/registerSuccess/index.vue").then(m => m.default || m)
  },
  {
    name: indexZ6ZuKsKAuRMeta?.name ?? "register-registerSuccess___de-de",
    path: indexZ6ZuKsKAuRMeta?.path ?? "/de-de/register/registerSuccess",
    meta: indexZ6ZuKsKAuRMeta || {},
    alias: indexZ6ZuKsKAuRMeta?.alias || [],
    redirect: indexZ6ZuKsKAuRMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/register/registerSuccess/index.vue").then(m => m.default || m)
  },
  {
    name: indexZ6ZuKsKAuRMeta?.name ?? "register-registerSuccess___en-us",
    path: indexZ6ZuKsKAuRMeta?.path ?? "/register/registerSuccess",
    meta: indexZ6ZuKsKAuRMeta || {},
    alias: indexZ6ZuKsKAuRMeta?.alias || [],
    redirect: indexZ6ZuKsKAuRMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/register/registerSuccess/index.vue").then(m => m.default || m)
  },
  {
    name: indexZ6ZuKsKAuRMeta?.name ?? "register-registerSuccess___es-es",
    path: indexZ6ZuKsKAuRMeta?.path ?? "/es-es/register/registerSuccess",
    meta: indexZ6ZuKsKAuRMeta || {},
    alias: indexZ6ZuKsKAuRMeta?.alias || [],
    redirect: indexZ6ZuKsKAuRMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/register/registerSuccess/index.vue").then(m => m.default || m)
  },
  {
    name: indexZ6ZuKsKAuRMeta?.name ?? "register-registerSuccess___fr-fr",
    path: indexZ6ZuKsKAuRMeta?.path ?? "/fr-fr/register/registerSuccess",
    meta: indexZ6ZuKsKAuRMeta || {},
    alias: indexZ6ZuKsKAuRMeta?.alias || [],
    redirect: indexZ6ZuKsKAuRMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/register/registerSuccess/index.vue").then(m => m.default || m)
  },
  {
    name: indexZ6ZuKsKAuRMeta?.name ?? "register-registerSuccess___id-id",
    path: indexZ6ZuKsKAuRMeta?.path ?? "/id-id/register/registerSuccess",
    meta: indexZ6ZuKsKAuRMeta || {},
    alias: indexZ6ZuKsKAuRMeta?.alias || [],
    redirect: indexZ6ZuKsKAuRMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/register/registerSuccess/index.vue").then(m => m.default || m)
  },
  {
    name: indexZ6ZuKsKAuRMeta?.name ?? "register-registerSuccess___it-it",
    path: indexZ6ZuKsKAuRMeta?.path ?? "/it-it/register/registerSuccess",
    meta: indexZ6ZuKsKAuRMeta || {},
    alias: indexZ6ZuKsKAuRMeta?.alias || [],
    redirect: indexZ6ZuKsKAuRMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/register/registerSuccess/index.vue").then(m => m.default || m)
  },
  {
    name: indexZ6ZuKsKAuRMeta?.name ?? "register-registerSuccess___ja-jp",
    path: indexZ6ZuKsKAuRMeta?.path ?? "/ja-jp/register/registerSuccess",
    meta: indexZ6ZuKsKAuRMeta || {},
    alias: indexZ6ZuKsKAuRMeta?.alias || [],
    redirect: indexZ6ZuKsKAuRMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/register/registerSuccess/index.vue").then(m => m.default || m)
  },
  {
    name: indexZ6ZuKsKAuRMeta?.name ?? "register-registerSuccess___ko-kr",
    path: indexZ6ZuKsKAuRMeta?.path ?? "/ko-kr/register/registerSuccess",
    meta: indexZ6ZuKsKAuRMeta || {},
    alias: indexZ6ZuKsKAuRMeta?.alias || [],
    redirect: indexZ6ZuKsKAuRMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/register/registerSuccess/index.vue").then(m => m.default || m)
  },
  {
    name: indexZ6ZuKsKAuRMeta?.name ?? "register-registerSuccess___pl-pl",
    path: indexZ6ZuKsKAuRMeta?.path ?? "/pl-pl/register/registerSuccess",
    meta: indexZ6ZuKsKAuRMeta || {},
    alias: indexZ6ZuKsKAuRMeta?.alias || [],
    redirect: indexZ6ZuKsKAuRMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/register/registerSuccess/index.vue").then(m => m.default || m)
  },
  {
    name: indexZ6ZuKsKAuRMeta?.name ?? "register-registerSuccess___pt-br",
    path: indexZ6ZuKsKAuRMeta?.path ?? "/pt-br/register/registerSuccess",
    meta: indexZ6ZuKsKAuRMeta || {},
    alias: indexZ6ZuKsKAuRMeta?.alias || [],
    redirect: indexZ6ZuKsKAuRMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/register/registerSuccess/index.vue").then(m => m.default || m)
  },
  {
    name: indexZ6ZuKsKAuRMeta?.name ?? "register-registerSuccess___pt-pt",
    path: indexZ6ZuKsKAuRMeta?.path ?? "/pt-pt/register/registerSuccess",
    meta: indexZ6ZuKsKAuRMeta || {},
    alias: indexZ6ZuKsKAuRMeta?.alias || [],
    redirect: indexZ6ZuKsKAuRMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/register/registerSuccess/index.vue").then(m => m.default || m)
  },
  {
    name: indexZ6ZuKsKAuRMeta?.name ?? "register-registerSuccess___ru-ru",
    path: indexZ6ZuKsKAuRMeta?.path ?? "/ru-ru/register/registerSuccess",
    meta: indexZ6ZuKsKAuRMeta || {},
    alias: indexZ6ZuKsKAuRMeta?.alias || [],
    redirect: indexZ6ZuKsKAuRMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/register/registerSuccess/index.vue").then(m => m.default || m)
  },
  {
    name: indexZ6ZuKsKAuRMeta?.name ?? "register-registerSuccess___th-th",
    path: indexZ6ZuKsKAuRMeta?.path ?? "/th-th/register/registerSuccess",
    meta: indexZ6ZuKsKAuRMeta || {},
    alias: indexZ6ZuKsKAuRMeta?.alias || [],
    redirect: indexZ6ZuKsKAuRMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/register/registerSuccess/index.vue").then(m => m.default || m)
  },
  {
    name: indexZ6ZuKsKAuRMeta?.name ?? "register-registerSuccess___tr-tr",
    path: indexZ6ZuKsKAuRMeta?.path ?? "/tr-tr/register/registerSuccess",
    meta: indexZ6ZuKsKAuRMeta || {},
    alias: indexZ6ZuKsKAuRMeta?.alias || [],
    redirect: indexZ6ZuKsKAuRMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/register/registerSuccess/index.vue").then(m => m.default || m)
  },
  {
    name: indexZ6ZuKsKAuRMeta?.name ?? "register-registerSuccess___uk-ua",
    path: indexZ6ZuKsKAuRMeta?.path ?? "/uk-ua/register/registerSuccess",
    meta: indexZ6ZuKsKAuRMeta || {},
    alias: indexZ6ZuKsKAuRMeta?.alias || [],
    redirect: indexZ6ZuKsKAuRMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/register/registerSuccess/index.vue").then(m => m.default || m)
  },
  {
    name: indexZ6ZuKsKAuRMeta?.name ?? "register-registerSuccess___vi-vn",
    path: indexZ6ZuKsKAuRMeta?.path ?? "/vi-vn/register/registerSuccess",
    meta: indexZ6ZuKsKAuRMeta || {},
    alias: indexZ6ZuKsKAuRMeta?.alias || [],
    redirect: indexZ6ZuKsKAuRMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/register/registerSuccess/index.vue").then(m => m.default || m)
  },
  {
    name: indexZ6ZuKsKAuRMeta?.name ?? "register-registerSuccess___zh-cn",
    path: indexZ6ZuKsKAuRMeta?.path ?? "/zh-cn/register/registerSuccess",
    meta: indexZ6ZuKsKAuRMeta || {},
    alias: indexZ6ZuKsKAuRMeta?.alias || [],
    redirect: indexZ6ZuKsKAuRMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/register/registerSuccess/index.vue").then(m => m.default || m)
  },
  {
    name: indexZ6ZuKsKAuRMeta?.name ?? "register-registerSuccess___zh-tw",
    path: indexZ6ZuKsKAuRMeta?.path ?? "/zh-tw/register/registerSuccess",
    meta: indexZ6ZuKsKAuRMeta || {},
    alias: indexZ6ZuKsKAuRMeta?.alias || [],
    redirect: indexZ6ZuKsKAuRMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/register/registerSuccess/index.vue").then(m => m.default || m)
  },
  {
    name: indexCIsB6rhX5gMeta?.name ?? "registerPC___ar-sa",
    path: indexCIsB6rhX5gMeta?.path ?? "/ar-sa/registerPC",
    meta: indexCIsB6rhX5gMeta || {},
    alias: indexCIsB6rhX5gMeta?.alias || [],
    redirect: indexCIsB6rhX5gMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/index.vue").then(m => m.default || m)
  },
  {
    name: indexCIsB6rhX5gMeta?.name ?? "registerPC___de-de",
    path: indexCIsB6rhX5gMeta?.path ?? "/de-de/registerPC",
    meta: indexCIsB6rhX5gMeta || {},
    alias: indexCIsB6rhX5gMeta?.alias || [],
    redirect: indexCIsB6rhX5gMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/index.vue").then(m => m.default || m)
  },
  {
    name: indexCIsB6rhX5gMeta?.name ?? "registerPC___en-us",
    path: indexCIsB6rhX5gMeta?.path ?? "/registerPC",
    meta: indexCIsB6rhX5gMeta || {},
    alias: indexCIsB6rhX5gMeta?.alias || [],
    redirect: indexCIsB6rhX5gMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/index.vue").then(m => m.default || m)
  },
  {
    name: indexCIsB6rhX5gMeta?.name ?? "registerPC___es-es",
    path: indexCIsB6rhX5gMeta?.path ?? "/es-es/registerPC",
    meta: indexCIsB6rhX5gMeta || {},
    alias: indexCIsB6rhX5gMeta?.alias || [],
    redirect: indexCIsB6rhX5gMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/index.vue").then(m => m.default || m)
  },
  {
    name: indexCIsB6rhX5gMeta?.name ?? "registerPC___fr-fr",
    path: indexCIsB6rhX5gMeta?.path ?? "/fr-fr/registerPC",
    meta: indexCIsB6rhX5gMeta || {},
    alias: indexCIsB6rhX5gMeta?.alias || [],
    redirect: indexCIsB6rhX5gMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/index.vue").then(m => m.default || m)
  },
  {
    name: indexCIsB6rhX5gMeta?.name ?? "registerPC___id-id",
    path: indexCIsB6rhX5gMeta?.path ?? "/id-id/registerPC",
    meta: indexCIsB6rhX5gMeta || {},
    alias: indexCIsB6rhX5gMeta?.alias || [],
    redirect: indexCIsB6rhX5gMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/index.vue").then(m => m.default || m)
  },
  {
    name: indexCIsB6rhX5gMeta?.name ?? "registerPC___it-it",
    path: indexCIsB6rhX5gMeta?.path ?? "/it-it/registerPC",
    meta: indexCIsB6rhX5gMeta || {},
    alias: indexCIsB6rhX5gMeta?.alias || [],
    redirect: indexCIsB6rhX5gMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/index.vue").then(m => m.default || m)
  },
  {
    name: indexCIsB6rhX5gMeta?.name ?? "registerPC___ja-jp",
    path: indexCIsB6rhX5gMeta?.path ?? "/ja-jp/registerPC",
    meta: indexCIsB6rhX5gMeta || {},
    alias: indexCIsB6rhX5gMeta?.alias || [],
    redirect: indexCIsB6rhX5gMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/index.vue").then(m => m.default || m)
  },
  {
    name: indexCIsB6rhX5gMeta?.name ?? "registerPC___ko-kr",
    path: indexCIsB6rhX5gMeta?.path ?? "/ko-kr/registerPC",
    meta: indexCIsB6rhX5gMeta || {},
    alias: indexCIsB6rhX5gMeta?.alias || [],
    redirect: indexCIsB6rhX5gMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/index.vue").then(m => m.default || m)
  },
  {
    name: indexCIsB6rhX5gMeta?.name ?? "registerPC___pl-pl",
    path: indexCIsB6rhX5gMeta?.path ?? "/pl-pl/registerPC",
    meta: indexCIsB6rhX5gMeta || {},
    alias: indexCIsB6rhX5gMeta?.alias || [],
    redirect: indexCIsB6rhX5gMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/index.vue").then(m => m.default || m)
  },
  {
    name: indexCIsB6rhX5gMeta?.name ?? "registerPC___pt-br",
    path: indexCIsB6rhX5gMeta?.path ?? "/pt-br/registerPC",
    meta: indexCIsB6rhX5gMeta || {},
    alias: indexCIsB6rhX5gMeta?.alias || [],
    redirect: indexCIsB6rhX5gMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/index.vue").then(m => m.default || m)
  },
  {
    name: indexCIsB6rhX5gMeta?.name ?? "registerPC___pt-pt",
    path: indexCIsB6rhX5gMeta?.path ?? "/pt-pt/registerPC",
    meta: indexCIsB6rhX5gMeta || {},
    alias: indexCIsB6rhX5gMeta?.alias || [],
    redirect: indexCIsB6rhX5gMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/index.vue").then(m => m.default || m)
  },
  {
    name: indexCIsB6rhX5gMeta?.name ?? "registerPC___ru-ru",
    path: indexCIsB6rhX5gMeta?.path ?? "/ru-ru/registerPC",
    meta: indexCIsB6rhX5gMeta || {},
    alias: indexCIsB6rhX5gMeta?.alias || [],
    redirect: indexCIsB6rhX5gMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/index.vue").then(m => m.default || m)
  },
  {
    name: indexCIsB6rhX5gMeta?.name ?? "registerPC___th-th",
    path: indexCIsB6rhX5gMeta?.path ?? "/th-th/registerPC",
    meta: indexCIsB6rhX5gMeta || {},
    alias: indexCIsB6rhX5gMeta?.alias || [],
    redirect: indexCIsB6rhX5gMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/index.vue").then(m => m.default || m)
  },
  {
    name: indexCIsB6rhX5gMeta?.name ?? "registerPC___tr-tr",
    path: indexCIsB6rhX5gMeta?.path ?? "/tr-tr/registerPC",
    meta: indexCIsB6rhX5gMeta || {},
    alias: indexCIsB6rhX5gMeta?.alias || [],
    redirect: indexCIsB6rhX5gMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/index.vue").then(m => m.default || m)
  },
  {
    name: indexCIsB6rhX5gMeta?.name ?? "registerPC___uk-ua",
    path: indexCIsB6rhX5gMeta?.path ?? "/uk-ua/registerPC",
    meta: indexCIsB6rhX5gMeta || {},
    alias: indexCIsB6rhX5gMeta?.alias || [],
    redirect: indexCIsB6rhX5gMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/index.vue").then(m => m.default || m)
  },
  {
    name: indexCIsB6rhX5gMeta?.name ?? "registerPC___vi-vn",
    path: indexCIsB6rhX5gMeta?.path ?? "/vi-vn/registerPC",
    meta: indexCIsB6rhX5gMeta || {},
    alias: indexCIsB6rhX5gMeta?.alias || [],
    redirect: indexCIsB6rhX5gMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/index.vue").then(m => m.default || m)
  },
  {
    name: indexCIsB6rhX5gMeta?.name ?? "registerPC___zh-cn",
    path: indexCIsB6rhX5gMeta?.path ?? "/zh-cn/registerPC",
    meta: indexCIsB6rhX5gMeta || {},
    alias: indexCIsB6rhX5gMeta?.alias || [],
    redirect: indexCIsB6rhX5gMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/index.vue").then(m => m.default || m)
  },
  {
    name: indexCIsB6rhX5gMeta?.name ?? "registerPC___zh-tw",
    path: indexCIsB6rhX5gMeta?.path ?? "/zh-tw/registerPC",
    meta: indexCIsB6rhX5gMeta || {},
    alias: indexCIsB6rhX5gMeta?.alias || [],
    redirect: indexCIsB6rhX5gMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/index.vue").then(m => m.default || m)
  },
  {
    name: indexVZzDUik1ENMeta?.name ?? "registerPC-registerCode___ar-sa",
    path: indexVZzDUik1ENMeta?.path ?? "/ar-sa/registerPC/registerCode",
    meta: indexVZzDUik1ENMeta || {},
    alias: indexVZzDUik1ENMeta?.alias || [],
    redirect: indexVZzDUik1ENMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/registerCode/index.vue").then(m => m.default || m)
  },
  {
    name: indexVZzDUik1ENMeta?.name ?? "registerPC-registerCode___de-de",
    path: indexVZzDUik1ENMeta?.path ?? "/de-de/registerPC/registerCode",
    meta: indexVZzDUik1ENMeta || {},
    alias: indexVZzDUik1ENMeta?.alias || [],
    redirect: indexVZzDUik1ENMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/registerCode/index.vue").then(m => m.default || m)
  },
  {
    name: indexVZzDUik1ENMeta?.name ?? "registerPC-registerCode___en-us",
    path: indexVZzDUik1ENMeta?.path ?? "/registerPC/registerCode",
    meta: indexVZzDUik1ENMeta || {},
    alias: indexVZzDUik1ENMeta?.alias || [],
    redirect: indexVZzDUik1ENMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/registerCode/index.vue").then(m => m.default || m)
  },
  {
    name: indexVZzDUik1ENMeta?.name ?? "registerPC-registerCode___es-es",
    path: indexVZzDUik1ENMeta?.path ?? "/es-es/registerPC/registerCode",
    meta: indexVZzDUik1ENMeta || {},
    alias: indexVZzDUik1ENMeta?.alias || [],
    redirect: indexVZzDUik1ENMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/registerCode/index.vue").then(m => m.default || m)
  },
  {
    name: indexVZzDUik1ENMeta?.name ?? "registerPC-registerCode___fr-fr",
    path: indexVZzDUik1ENMeta?.path ?? "/fr-fr/registerPC/registerCode",
    meta: indexVZzDUik1ENMeta || {},
    alias: indexVZzDUik1ENMeta?.alias || [],
    redirect: indexVZzDUik1ENMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/registerCode/index.vue").then(m => m.default || m)
  },
  {
    name: indexVZzDUik1ENMeta?.name ?? "registerPC-registerCode___id-id",
    path: indexVZzDUik1ENMeta?.path ?? "/id-id/registerPC/registerCode",
    meta: indexVZzDUik1ENMeta || {},
    alias: indexVZzDUik1ENMeta?.alias || [],
    redirect: indexVZzDUik1ENMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/registerCode/index.vue").then(m => m.default || m)
  },
  {
    name: indexVZzDUik1ENMeta?.name ?? "registerPC-registerCode___it-it",
    path: indexVZzDUik1ENMeta?.path ?? "/it-it/registerPC/registerCode",
    meta: indexVZzDUik1ENMeta || {},
    alias: indexVZzDUik1ENMeta?.alias || [],
    redirect: indexVZzDUik1ENMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/registerCode/index.vue").then(m => m.default || m)
  },
  {
    name: indexVZzDUik1ENMeta?.name ?? "registerPC-registerCode___ja-jp",
    path: indexVZzDUik1ENMeta?.path ?? "/ja-jp/registerPC/registerCode",
    meta: indexVZzDUik1ENMeta || {},
    alias: indexVZzDUik1ENMeta?.alias || [],
    redirect: indexVZzDUik1ENMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/registerCode/index.vue").then(m => m.default || m)
  },
  {
    name: indexVZzDUik1ENMeta?.name ?? "registerPC-registerCode___ko-kr",
    path: indexVZzDUik1ENMeta?.path ?? "/ko-kr/registerPC/registerCode",
    meta: indexVZzDUik1ENMeta || {},
    alias: indexVZzDUik1ENMeta?.alias || [],
    redirect: indexVZzDUik1ENMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/registerCode/index.vue").then(m => m.default || m)
  },
  {
    name: indexVZzDUik1ENMeta?.name ?? "registerPC-registerCode___pl-pl",
    path: indexVZzDUik1ENMeta?.path ?? "/pl-pl/registerPC/registerCode",
    meta: indexVZzDUik1ENMeta || {},
    alias: indexVZzDUik1ENMeta?.alias || [],
    redirect: indexVZzDUik1ENMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/registerCode/index.vue").then(m => m.default || m)
  },
  {
    name: indexVZzDUik1ENMeta?.name ?? "registerPC-registerCode___pt-br",
    path: indexVZzDUik1ENMeta?.path ?? "/pt-br/registerPC/registerCode",
    meta: indexVZzDUik1ENMeta || {},
    alias: indexVZzDUik1ENMeta?.alias || [],
    redirect: indexVZzDUik1ENMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/registerCode/index.vue").then(m => m.default || m)
  },
  {
    name: indexVZzDUik1ENMeta?.name ?? "registerPC-registerCode___pt-pt",
    path: indexVZzDUik1ENMeta?.path ?? "/pt-pt/registerPC/registerCode",
    meta: indexVZzDUik1ENMeta || {},
    alias: indexVZzDUik1ENMeta?.alias || [],
    redirect: indexVZzDUik1ENMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/registerCode/index.vue").then(m => m.default || m)
  },
  {
    name: indexVZzDUik1ENMeta?.name ?? "registerPC-registerCode___ru-ru",
    path: indexVZzDUik1ENMeta?.path ?? "/ru-ru/registerPC/registerCode",
    meta: indexVZzDUik1ENMeta || {},
    alias: indexVZzDUik1ENMeta?.alias || [],
    redirect: indexVZzDUik1ENMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/registerCode/index.vue").then(m => m.default || m)
  },
  {
    name: indexVZzDUik1ENMeta?.name ?? "registerPC-registerCode___th-th",
    path: indexVZzDUik1ENMeta?.path ?? "/th-th/registerPC/registerCode",
    meta: indexVZzDUik1ENMeta || {},
    alias: indexVZzDUik1ENMeta?.alias || [],
    redirect: indexVZzDUik1ENMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/registerCode/index.vue").then(m => m.default || m)
  },
  {
    name: indexVZzDUik1ENMeta?.name ?? "registerPC-registerCode___tr-tr",
    path: indexVZzDUik1ENMeta?.path ?? "/tr-tr/registerPC/registerCode",
    meta: indexVZzDUik1ENMeta || {},
    alias: indexVZzDUik1ENMeta?.alias || [],
    redirect: indexVZzDUik1ENMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/registerCode/index.vue").then(m => m.default || m)
  },
  {
    name: indexVZzDUik1ENMeta?.name ?? "registerPC-registerCode___uk-ua",
    path: indexVZzDUik1ENMeta?.path ?? "/uk-ua/registerPC/registerCode",
    meta: indexVZzDUik1ENMeta || {},
    alias: indexVZzDUik1ENMeta?.alias || [],
    redirect: indexVZzDUik1ENMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/registerCode/index.vue").then(m => m.default || m)
  },
  {
    name: indexVZzDUik1ENMeta?.name ?? "registerPC-registerCode___vi-vn",
    path: indexVZzDUik1ENMeta?.path ?? "/vi-vn/registerPC/registerCode",
    meta: indexVZzDUik1ENMeta || {},
    alias: indexVZzDUik1ENMeta?.alias || [],
    redirect: indexVZzDUik1ENMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/registerCode/index.vue").then(m => m.default || m)
  },
  {
    name: indexVZzDUik1ENMeta?.name ?? "registerPC-registerCode___zh-cn",
    path: indexVZzDUik1ENMeta?.path ?? "/zh-cn/registerPC/registerCode",
    meta: indexVZzDUik1ENMeta || {},
    alias: indexVZzDUik1ENMeta?.alias || [],
    redirect: indexVZzDUik1ENMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/registerCode/index.vue").then(m => m.default || m)
  },
  {
    name: indexVZzDUik1ENMeta?.name ?? "registerPC-registerCode___zh-tw",
    path: indexVZzDUik1ENMeta?.path ?? "/zh-tw/registerPC/registerCode",
    meta: indexVZzDUik1ENMeta || {},
    alias: indexVZzDUik1ENMeta?.alias || [],
    redirect: indexVZzDUik1ENMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/registerCode/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJ3oGAj4vEMeta?.name ?? "registerPC-registerSuccess___ar-sa",
    path: indexwJ3oGAj4vEMeta?.path ?? "/ar-sa/registerPC/registerSuccess",
    meta: indexwJ3oGAj4vEMeta || {},
    alias: indexwJ3oGAj4vEMeta?.alias || [],
    redirect: indexwJ3oGAj4vEMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/registerSuccess/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJ3oGAj4vEMeta?.name ?? "registerPC-registerSuccess___de-de",
    path: indexwJ3oGAj4vEMeta?.path ?? "/de-de/registerPC/registerSuccess",
    meta: indexwJ3oGAj4vEMeta || {},
    alias: indexwJ3oGAj4vEMeta?.alias || [],
    redirect: indexwJ3oGAj4vEMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/registerSuccess/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJ3oGAj4vEMeta?.name ?? "registerPC-registerSuccess___en-us",
    path: indexwJ3oGAj4vEMeta?.path ?? "/registerPC/registerSuccess",
    meta: indexwJ3oGAj4vEMeta || {},
    alias: indexwJ3oGAj4vEMeta?.alias || [],
    redirect: indexwJ3oGAj4vEMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/registerSuccess/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJ3oGAj4vEMeta?.name ?? "registerPC-registerSuccess___es-es",
    path: indexwJ3oGAj4vEMeta?.path ?? "/es-es/registerPC/registerSuccess",
    meta: indexwJ3oGAj4vEMeta || {},
    alias: indexwJ3oGAj4vEMeta?.alias || [],
    redirect: indexwJ3oGAj4vEMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/registerSuccess/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJ3oGAj4vEMeta?.name ?? "registerPC-registerSuccess___fr-fr",
    path: indexwJ3oGAj4vEMeta?.path ?? "/fr-fr/registerPC/registerSuccess",
    meta: indexwJ3oGAj4vEMeta || {},
    alias: indexwJ3oGAj4vEMeta?.alias || [],
    redirect: indexwJ3oGAj4vEMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/registerSuccess/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJ3oGAj4vEMeta?.name ?? "registerPC-registerSuccess___id-id",
    path: indexwJ3oGAj4vEMeta?.path ?? "/id-id/registerPC/registerSuccess",
    meta: indexwJ3oGAj4vEMeta || {},
    alias: indexwJ3oGAj4vEMeta?.alias || [],
    redirect: indexwJ3oGAj4vEMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/registerSuccess/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJ3oGAj4vEMeta?.name ?? "registerPC-registerSuccess___it-it",
    path: indexwJ3oGAj4vEMeta?.path ?? "/it-it/registerPC/registerSuccess",
    meta: indexwJ3oGAj4vEMeta || {},
    alias: indexwJ3oGAj4vEMeta?.alias || [],
    redirect: indexwJ3oGAj4vEMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/registerSuccess/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJ3oGAj4vEMeta?.name ?? "registerPC-registerSuccess___ja-jp",
    path: indexwJ3oGAj4vEMeta?.path ?? "/ja-jp/registerPC/registerSuccess",
    meta: indexwJ3oGAj4vEMeta || {},
    alias: indexwJ3oGAj4vEMeta?.alias || [],
    redirect: indexwJ3oGAj4vEMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/registerSuccess/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJ3oGAj4vEMeta?.name ?? "registerPC-registerSuccess___ko-kr",
    path: indexwJ3oGAj4vEMeta?.path ?? "/ko-kr/registerPC/registerSuccess",
    meta: indexwJ3oGAj4vEMeta || {},
    alias: indexwJ3oGAj4vEMeta?.alias || [],
    redirect: indexwJ3oGAj4vEMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/registerSuccess/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJ3oGAj4vEMeta?.name ?? "registerPC-registerSuccess___pl-pl",
    path: indexwJ3oGAj4vEMeta?.path ?? "/pl-pl/registerPC/registerSuccess",
    meta: indexwJ3oGAj4vEMeta || {},
    alias: indexwJ3oGAj4vEMeta?.alias || [],
    redirect: indexwJ3oGAj4vEMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/registerSuccess/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJ3oGAj4vEMeta?.name ?? "registerPC-registerSuccess___pt-br",
    path: indexwJ3oGAj4vEMeta?.path ?? "/pt-br/registerPC/registerSuccess",
    meta: indexwJ3oGAj4vEMeta || {},
    alias: indexwJ3oGAj4vEMeta?.alias || [],
    redirect: indexwJ3oGAj4vEMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/registerSuccess/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJ3oGAj4vEMeta?.name ?? "registerPC-registerSuccess___pt-pt",
    path: indexwJ3oGAj4vEMeta?.path ?? "/pt-pt/registerPC/registerSuccess",
    meta: indexwJ3oGAj4vEMeta || {},
    alias: indexwJ3oGAj4vEMeta?.alias || [],
    redirect: indexwJ3oGAj4vEMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/registerSuccess/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJ3oGAj4vEMeta?.name ?? "registerPC-registerSuccess___ru-ru",
    path: indexwJ3oGAj4vEMeta?.path ?? "/ru-ru/registerPC/registerSuccess",
    meta: indexwJ3oGAj4vEMeta || {},
    alias: indexwJ3oGAj4vEMeta?.alias || [],
    redirect: indexwJ3oGAj4vEMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/registerSuccess/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJ3oGAj4vEMeta?.name ?? "registerPC-registerSuccess___th-th",
    path: indexwJ3oGAj4vEMeta?.path ?? "/th-th/registerPC/registerSuccess",
    meta: indexwJ3oGAj4vEMeta || {},
    alias: indexwJ3oGAj4vEMeta?.alias || [],
    redirect: indexwJ3oGAj4vEMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/registerSuccess/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJ3oGAj4vEMeta?.name ?? "registerPC-registerSuccess___tr-tr",
    path: indexwJ3oGAj4vEMeta?.path ?? "/tr-tr/registerPC/registerSuccess",
    meta: indexwJ3oGAj4vEMeta || {},
    alias: indexwJ3oGAj4vEMeta?.alias || [],
    redirect: indexwJ3oGAj4vEMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/registerSuccess/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJ3oGAj4vEMeta?.name ?? "registerPC-registerSuccess___uk-ua",
    path: indexwJ3oGAj4vEMeta?.path ?? "/uk-ua/registerPC/registerSuccess",
    meta: indexwJ3oGAj4vEMeta || {},
    alias: indexwJ3oGAj4vEMeta?.alias || [],
    redirect: indexwJ3oGAj4vEMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/registerSuccess/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJ3oGAj4vEMeta?.name ?? "registerPC-registerSuccess___vi-vn",
    path: indexwJ3oGAj4vEMeta?.path ?? "/vi-vn/registerPC/registerSuccess",
    meta: indexwJ3oGAj4vEMeta || {},
    alias: indexwJ3oGAj4vEMeta?.alias || [],
    redirect: indexwJ3oGAj4vEMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/registerSuccess/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJ3oGAj4vEMeta?.name ?? "registerPC-registerSuccess___zh-cn",
    path: indexwJ3oGAj4vEMeta?.path ?? "/zh-cn/registerPC/registerSuccess",
    meta: indexwJ3oGAj4vEMeta || {},
    alias: indexwJ3oGAj4vEMeta?.alias || [],
    redirect: indexwJ3oGAj4vEMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/registerSuccess/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJ3oGAj4vEMeta?.name ?? "registerPC-registerSuccess___zh-tw",
    path: indexwJ3oGAj4vEMeta?.path ?? "/zh-tw/registerPC/registerSuccess",
    meta: indexwJ3oGAj4vEMeta || {},
    alias: indexwJ3oGAj4vEMeta?.alias || [],
    redirect: indexwJ3oGAj4vEMeta?.redirect || undefined,
    component: () => import("/home/wwwroot/butter_html/apps/h5/src/pages/registerPC/registerSuccess/index.vue").then(m => m.default || m)
  }
]