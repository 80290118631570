import revive_payload_client_SNAdOYGnA1 from "/home/wwwroot/butter_html/node_modules/.pnpm/nuxt@3.7.4_@types+node@20.4.5_eslint@8.33.0_less@4.1.3_rollup@2.79.1_sass@1.72.0_stylelint@14_swc2fr5iwlw2lfaqt7yfv7ytmq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_ryiEU7hWHC from "/home/wwwroot/butter_html/node_modules/.pnpm/nuxt@3.7.4_@types+node@20.4.5_eslint@8.33.0_less@4.1.3_rollup@2.79.1_sass@1.72.0_stylelint@14_swc2fr5iwlw2lfaqt7yfv7ytmq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_iR9ksWIwzK from "/home/wwwroot/butter_html/node_modules/.pnpm/nuxt@3.7.4_@types+node@20.4.5_eslint@8.33.0_less@4.1.3_rollup@2.79.1_sass@1.72.0_stylelint@14_swc2fr5iwlw2lfaqt7yfv7ytmq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_eXEeKrmzAm from "/home/wwwroot/butter_html/node_modules/.pnpm/nuxt-site-config@1.5.5_rollup@2.79.1_vue@3.3.4/node_modules/nuxt-site-config/dist/runtime/plugins/0.siteConfig.mjs";
import payload_client_SE4gsdRKWY from "/home/wwwroot/butter_html/node_modules/.pnpm/nuxt@3.7.4_@types+node@20.4.5_eslint@8.33.0_less@4.1.3_rollup@2.79.1_sass@1.72.0_stylelint@14_swc2fr5iwlw2lfaqt7yfv7ytmq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_2yvGTqeqEm from "/home/wwwroot/butter_html/node_modules/.pnpm/@pinia+nuxt@0.4.11_rollup@2.79.1_typescript@5.0.2_vue@3.3.4/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/wwwroot/butter_html/apps/h5/.nuxt/components.plugin.mjs";
import prefetch_client_S5O3slugHN from "/home/wwwroot/butter_html/node_modules/.pnpm/nuxt@3.7.4_@types+node@20.4.5_eslint@8.33.0_less@4.1.3_rollup@2.79.1_sass@1.72.0_stylelint@14_swc2fr5iwlw2lfaqt7yfv7ytmq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import templates_pwa_client_0ad65c61_RpRezgjO7C from "/home/wwwroot/butter_html/apps/h5/.nuxt/templates.pwa.client.0ad65c61.ts";
import i18n_TNHmhNvu2i from "/home/wwwroot/butter_html/node_modules/.pnpm/nuxt-site-config@1.5.5_rollup@2.79.1_vue@3.3.4/node_modules/nuxt-site-config/dist/runtime/plugins/i18n.mjs";
import composition_ZQNW2SQmXr from "/home/wwwroot/butter_html/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-rc.2_rollup@2.79.1_vue-router@4.1.6_vue@3.3.4/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import meta_oY1naARLVF from "/home/wwwroot/butter_html/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-rc.2_rollup@2.79.1_vue-router@4.1.6_vue@3.3.4/node_modules/@nuxtjs/i18n/dist/runtime/plugins/meta.mjs";
import i18n_xBUZjb59JA from "/home/wwwroot/butter_html/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-rc.2_rollup@2.79.1_vue-router@4.1.6_vue@3.3.4/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import unocss_MzCDxu9LMj from "/home/wwwroot/butter_html/apps/h5/.nuxt/unocss.mjs";
import chunk_reload_client_YF0Z2FivmP from "/home/wwwroot/butter_html/node_modules/.pnpm/nuxt@3.7.4_@types+node@20.4.5_eslint@8.33.0_less@4.1.3_rollup@2.79.1_sass@1.72.0_stylelint@14_swc2fr5iwlw2lfaqt7yfv7ytmq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import route_bQWBGeaCfs from "/home/wwwroot/butter_html/apps/h5/src/plugins/route.ts";
import init_0XaaHfJsUz from "/home/wwwroot/butter_html/apps/h5/src/plugins/init.ts";
export default [
  revive_payload_client_SNAdOYGnA1,
  unhead_ryiEU7hWHC,
  router_iR9ksWIwzK,
  _0_siteConfig_eXEeKrmzAm,
  payload_client_SE4gsdRKWY,
  plugin_vue3_2yvGTqeqEm,
  components_plugin_KR1HBZs4kY,
  prefetch_client_S5O3slugHN,
  templates_pwa_client_0ad65c61_RpRezgjO7C,
  i18n_TNHmhNvu2i,
  composition_ZQNW2SQmXr,
  meta_oY1naARLVF,
  i18n_xBUZjb59JA,
  unocss_MzCDxu9LMj,
  chunk_reload_client_YF0Z2FivmP,
  route_bQWBGeaCfs,
  init_0XaaHfJsUz
]