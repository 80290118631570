export const localeCodes = ["ar-sa","de-de","en-us","es-es","fr-fr","id-id","it-it","ja-jp","ko-kr","pl-pl","pt-br","pt-pt","ru-ru","th-th","tr-tr","uk-ua","vi-vn","zh-cn","zh-tw"]

export const localeMessages = {
}

export const resolveNuxtI18nOptions = async (context) => {
  const nuxtI18nOptions = Object({})
  nuxtI18nOptions.experimental = Object({"jsTsFormatResource":false})
  nuxtI18nOptions.compilation = Object({"jit":true,"strictMessage":false,"escapeHtml":false})
 const vueI18nConfigLoader = async (loader) => {
            const config = await loader().then(r => r.default || r)
            return typeof config === 'object'
              ? config
              : typeof config === 'function'
                ? await config()
                : {}
          }
  const vueI18n = await vueI18nConfigLoader((() => import("../i18n.config.ts?hash=fd3c83f5&config=1" /* webpackChunkName: "__i18n_config_ts_fd3c83f5" */)))
  nuxtI18nOptions.vueI18n = vueI18n
  nuxtI18nOptions.vueI18n.messages ??= {}
  nuxtI18nOptions.locales = [Object({"code":"ar-sa","iso":"ar-sa"}),Object({"code":"de-de","iso":"de-de"}),Object({"code":"en-us","iso":"en-us"}),Object({"code":"es-es","iso":"es-es"}),Object({"code":"fr-fr","iso":"fr-fr"}),Object({"code":"id-id","iso":"id-id"}),Object({"code":"it-it","iso":"it-it"}),Object({"code":"ja-jp","iso":"ja-jp"}),Object({"code":"ko-kr","iso":"ko-kr"}),Object({"code":"pl-pl","iso":"pl-pl"}),Object({"code":"pt-br","iso":"pt-br"}),Object({"code":"pt-pt","iso":"pt-pt"}),Object({"code":"ru-ru","iso":"ru-ru"}),Object({"code":"th-th","iso":"th-th"}),Object({"code":"tr-tr","iso":"tr-tr"}),Object({"code":"uk-ua","iso":"uk-ua"}),Object({"code":"vi-vn","iso":"vi-vn"}),Object({"code":"zh-cn","iso":"zh-cn"}),Object({"code":"zh-tw","iso":"zh-tw"})]
  nuxtI18nOptions.defaultLocale = "en-us"
  nuxtI18nOptions.defaultDirection = "ltr"
  nuxtI18nOptions.routesNameSeparator = "___"
  nuxtI18nOptions.trailingSlash = false
  nuxtI18nOptions.defaultLocaleRouteNameSuffix = "default"
  nuxtI18nOptions.strategy = "prefix_except_default"
  nuxtI18nOptions.lazy = false
  nuxtI18nOptions.langDir = null
  nuxtI18nOptions.rootRedirect = null
  nuxtI18nOptions.detectBrowserLanguage = false
  nuxtI18nOptions.differentDomains = false
  nuxtI18nOptions.baseUrl = "https://l7t6cczx.com"
  nuxtI18nOptions.dynamicRouteParams = true
  nuxtI18nOptions.customRoutes = "page"
  nuxtI18nOptions.pages = Object({})
  nuxtI18nOptions.skipSettingLocaleOnNavigate = false
  nuxtI18nOptions.types = "composition"
  nuxtI18nOptions.debug = false
  return nuxtI18nOptions
}

export const nuxtI18nOptionsDefault = Object({experimental: Object({"jsTsFormatResource":false}),compilation: Object({"jit":true,"strictMessage":true,"escapeHtml":false}),vueI18n: "",locales: [],defaultLocale: "",defaultDirection: "ltr",routesNameSeparator: "___",trailingSlash: false,defaultLocaleRouteNameSuffix: "default",strategy: "prefix_except_default",lazy: false,langDir: null,rootRedirect: null,detectBrowserLanguage: Object({"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true}),differentDomains: false,baseUrl: "",dynamicRouteParams: false,customRoutes: "page",pages: Object({}),skipSettingLocaleOnNavigate: false,types: "composition",debug: false})

export const nuxtI18nInternalOptions = Object({__normalizedLocales: [Object({"code":"ar-sa","iso":"ar-sa"}),Object({"code":"de-de","iso":"de-de"}),Object({"code":"en-us","iso":"en-us"}),Object({"code":"es-es","iso":"es-es"}),Object({"code":"fr-fr","iso":"fr-fr"}),Object({"code":"id-id","iso":"id-id"}),Object({"code":"it-it","iso":"it-it"}),Object({"code":"ja-jp","iso":"ja-jp"}),Object({"code":"ko-kr","iso":"ko-kr"}),Object({"code":"pl-pl","iso":"pl-pl"}),Object({"code":"pt-br","iso":"pt-br"}),Object({"code":"pt-pt","iso":"pt-pt"}),Object({"code":"ru-ru","iso":"ru-ru"}),Object({"code":"th-th","iso":"th-th"}),Object({"code":"tr-tr","iso":"tr-tr"}),Object({"code":"uk-ua","iso":"uk-ua"}),Object({"code":"vi-vn","iso":"vi-vn"}),Object({"code":"zh-cn","iso":"zh-cn"}),Object({"code":"zh-tw","iso":"zh-tw"})]})
export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const isSSG = false
